<template>
  <div class="project_user_setting">
    <el-dialog
      :title="$t('user.addUserForm.title')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialog_visible"
      :append-to-body="true"
      width="25%"
      custom-class="adduser_dialog"
    >
      <div class="label">
        <div class="name"><span>*</span>{{ $t("user.addUserForm.name") }}</div>
        <div class="position">
          <span>*</span>{{ $t("user.addUserForm.position") }}
        </div>
      </div>
      <div class="content">
        <el-scrollbar style="height: 100%">
          <el-form ref="addUserForm" :model="form">
            <div
              v-for="(temp, index) in form.user_arr"
              :key="index"
              class="content_item"
            >
              <el-form-item
                :prop="`user_arr.${index}.accountId`"
                :rules="rules.accountId"
              >
                <el-select
                  @clear="clearList(index)"
                  @visible-change="chooseisShow(index)"
                  v-model="temp.accountId"
                  clearable
                  filterable
                  @change="handlechangeGroup"
                >
                  <el-option-group
                    v-for="(group, flag) in groupandUser"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="user in group.options"
                      :key="user.value"
                      v-show="showEvery(index, user.value)"
                      :label="user.label"
                      :value="user.value + flag"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`user_arr.${index}.jobId`"
                :rules="rules.jobId"
              >
                <el-select v-model="temp.jobId" clearable filterable>
                  <el-option
                    v-for="item in job_select_list"
                    :key="item.jobId"
                    :label="item.name"
                    :value="item.jobId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </el-scrollbar>
      </div>

      <div class="addbtn">
        <img
          src="../../assets/img/addbtn.png"
          :alt="$t('user.addUserForm.title')"
          @click="add_form_item"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="layout">{{ $t("btn.cancelBtn") }}</el-button>
        <el-button type="primary" @click="save">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-row type="flex" justify="end">
      <el-button
        class="add-button"
        size="small"
        icon="el-icon-plus"
        type="primary"
        @click="clickAddButton"
      >
        {{ $t("user.addUserBtn") }}
      </el-button>
    </el-row>
    <el-row>
      <member-setting-table
        ref="memberSettingTable"
        :project_id="p_id || get_pid()"
        @user_in_project="(val) => (user_in_project = val)"
      >
      </member-setting-table>
    </el-row>
  </div>
</template>

<script>
import MemberSettingTable from "./components/membersettingTable.vue";
import { get_tenant_user_list_nopage } from "@/network/user/index.js";
import { add_project_member } from "@/network/projectMember/index.js";
import { get_jobAuthorities } from "@/network/home/index.js";
import { get_tenant_user_group_list_nopage } from "@/network/user/index.js";
import { mapActions } from "vuex";

export default {
  components: { MemberSettingTable },
  props: ["p_id"],
  data() {
    return {
      dialog_visible: false,
      rules: {
        accountId: [
          {
            required: true,
            message: this.$t("user.addUserForm.info1"),
            trigger: "change",
          },
        ],
        jobId: [
          {
            required: true,
            message: this.$t("user.addUserForm.info2"),
            trigger: "change",
          },
        ],
      },
      form: {
        user_arr: [
          {
            accountId: "",
            jobId: "",
            projectRole: "MEMBER",
          },
        ],
      },
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      allpushList: [],
      choosesomeList: ["a"],
      job_select_list: [],
      user_in_project: [],
      user_options: [],
      user_options_filtered: [],
      listIndex: 0,
      allArrlist: [],
      inproject: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getJobList();
  },
  methods: {
    ...mapActions(["setProjectUserList"]),
    layout() {
      this.dialog_visible = false;
      this.choosesomeList = [];
    },
    clearList(e) {
      this.choosesomeList[e] = "";
    },
    showEvery(a, b) {
      if (this.choosesomeList.includes(b)) {
        return false;
      } else {
        return true;
      }
    },
    chooseisShow(e) {
      this.listIndex = e;
    },
    handlechangeGroup(e) {
      let allList = JSON.parse(JSON.stringify(this.groupandUser));
      let chooseIs = e.slice(0, -1);
      this.choosesomeList[this.listIndex] = chooseIs;
      if (e.substr(-1) == 0) {
        allList[0].options = allList[0].options.filter(
          (obj) => obj.value !== chooseIs
        );
      } else {
        allList[1].options = allList[1].options.filter(
          (obj) => obj.value !== chooseIs
        );
      }
      this.allpushList = allList;
    },
    clickAddButton() {
      this.choosesomeList = [];
      this.dialog_visible = true;
      this.form.user_arr = [];
      this.add_form_item();
      this.$nextTick(() => this.$refs.addUserForm.resetFields());
      this.getUserList();
      this.getJobList();
    },
    getUserList() {
      let user_list = [];
      for (const key in this.$store.getters.user_list) {
        user_list.push(this.$store.getters.user_list[key]);
      }
      this.inproject = this.user_in_project;
      let accId_arr = this.user_in_project.map((item) => item.accountId);
      this.user_options = user_list.filter((item) => {
        return item.deleted != 1 && !accId_arr.includes(item.accountId);
      });
      this.setgroupandUser(this.user_options);
      this.user_options_filtered = this.user_options;
    },
    mergedUsergroup(arr) {
      let allList = JSON.parse(JSON.stringify(arr));
      allList.map((item, index) => {
        if (item.accountId.substr(-1) == 0) {
          item.accountId = item.accountId.slice(0, -1);
        } else if (item.accountId.substr(-1) == 1) {
          item.accountId = item.accountId.slice(0, -1);
        }
      });
      return allList;
    },
    setRequestGrouplist(arr, comparisonarr) {
      let seen = {};
      let transferList = [];
      let allList = [];
      let groupUserlist = JSON.parse(JSON.stringify(arr));
      groupUserlist.map((item, index) => {
        if (item.accountId.length != 19) {
          comparisonarr.map((obj) => {
            if (obj.groupId == item.accountId) {
              obj.accountIdList.map((flag) => {
                transferList.push({
                  accountId: flag,
                  jobId: item.jobId,
                  projectRole: item.projectRole,
                });
              });
            }
          });
        }
      });
      allList = transferList
        .concat(arr)
        .filter((item) => item.accountId.length == 19);
      allList.map((item, index) => {
        if (arr[0].accountId == item.accountId) {
          item.jobId = arr[0].jobId;
        }
      });
      let groupedItems = allList.filter(function (item) {
        if (!seen[item.accountId]) {
          seen[item.accountId] = true;
          return true;
        }
        return false;
      });
      const filteredArray1 = groupedItems.filter((obj1) => {
        return !this.inproject.some((obj2) => obj1.accountId === obj2.accountId);
      });
      return filteredArray1;
    },
    setgroupandUser(users) {
      this.groupandUser[0].options = [];
      this.groupandUser[1].options = [];
      const group = Object.values(this.$store.getters.user_group_list);
      users.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    // 获得职位列表
    getJobList() {
      get_jobAuthorities(this.p_id || this.get_pid()).then((res) => {
        this.job_select_list = res;
      });
    },
    add_form_item() {
      let obj = {
        accountId: "",
        jobId: "",
        projectRole: "MEMBER",
      };
      this.form.user_arr.push(obj);
    },
    async save() {
      this.form.user_arr = this.form.user_arr.filter((item) => {
        return item.accountId != "" || item.jobId != "";
      });
      const allgrouplist = await get_tenant_user_group_list_nopage();
      this.allArrlist = allgrouplist;
      if (this.form.user_arr.length < 1) {
        this.add_form_item();
        this.$message.warning(this.$t("user.addUserForm.warn"));
      } else {
        this.$nextTick(() => {
          this.submit();
        });
      }
    },
    submit() {
      const realallList = this.mergedUsergroup(this.form.user_arr);
      const tranlist = this.setRequestGrouplist(realallList, this.allArrlist);
      this.$refs["addUserForm"].validate((valid) => {
        if (valid) {
          if (tranlist.length != 0) {
            add_project_member(this.p_id || this.get_pid(), tranlist).then(
              () => {
                this.setProjectUserList(this.p_id || this.get_pid());
                this.dialog_visible = false;
                this.$message.success(
                  this.$t("user.userTable.message.addSuccess")
                );
                this.$refs.memberSettingTable.getMemberData();
              }
            );
          } else {
            this.dialog_visible = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  ::v-deep .adduser_dialog {
    .el-dialog__body {
      padding: 6px 41px 0;
      text-align: left;
      .tip {
        color: rgba(166, 166, 166, 1);
        font-size: 14px;
      }
      .label {
        display: flex;
        justify-content: space-between;
        margin: 18px 15px 7px;
        div {
          width: 150px;
          text-align: center;
          font-size: 16px;
          color: #383838;
          span {
            color: red;
          }
        }
      }
      .content {
        height: 188px;
        .el-scrollbar {
          .el-scrollbar__wrap {
            overflow-x: hidden;
            width: 100%;
            // 隐藏原生滚动条
            // 火狐
            scrollbar-width: none;
            // 谷歌
            &::-webkit-scrollbar {
              display: none;
            }

            &::-webkit-scrollbar-thumb {
              display: none;
            }
            // IE, Edge
            -ms-overflow-style: none;
            .el-scrollbar__view {
              & > div {
                display: flex;
                justify-content: space-between;
              }
            }
            .el-scrollbar__view {
              .el-form {
                padding: 0 15px;
                .content_item {
                  display: flex;
                  justify-content: space-between;
                  .el-form-item {
                    display: inline-block;
                    margin-bottom: 16px;
                    .el-input {
                      width: 150px;
                      height: 41px; /*no*/
                      .el-input__inner {
                        height: 100%;
                      }
                      .el-input__icon {
                        height: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .addbtn {
        img {
          display: block;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>
